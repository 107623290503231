<template>
  <div v-if="productDetail.isActive">
    <!-- breadcrumb -->
    <!-- border-top only show in mobile -->
    <div class="border-top d-block d-md-none" />
    <div class="border-bottom">
      <div class="container-lg">
        <div class="row">
          <div
            class="col-11 d-flex flex-row align-items-center mx-auto py-2 px-md-0 text-grey small"
          >
            <router-link to="/">
              <img
                src="@/assets/img/home.png"
                :class="$style[`icon`]"
              >
            </router-link>
            <div v-if="productDetail?.catMain">
              <span class="mx-1">></span>
              <router-link
                class="text-grey text-decoration-none"
                :to="'/product/list?catMainID=' + productDetail.catMainID"
              >
                {{ productDetail?.catMain || '' }}
              </router-link>
            </div>
            <div v-if="productDetail?.catSub">
              <span class="mx-1">></span>
              <router-link
                class="text-grey text-decoration-none"
                :to="
                  productDetail?.catMainID && productDetail?.catSubID
                    ? '/product/list?catMainID=' +
                      productDetail.catMainID +
                      '&catSubID=' +
                      productDetail.catSubID
                    : '#'
                "
              >
                {{ productDetail?.catSub || '' }}
              </router-link>
            </div>
            <!-- <a
              v-if="productDetail?.catSub"
              :href="productDetail?.catMainID && productDetail?.catSubID ?
                '/product/list?catMainID='+product.catMainID+'&catSubID='+product.catSubID
                : '#'"
            >
              <span class="mx-1">></span>
              {{ productDetail?.catSub || '' }}
            </a> -->
            <span
              v-if="productDetail?.name"
              class="text-dark"
            >
              <span class="mx-1 text-grey-dark">></span>
              {{ productDetail?.name || '' }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="container-lg my-5">
      <div class="row">
        <!-- left pic -->

        <!-- 輪播資訊 -->
        <div class="col-11 col-md-6 mx-auto">
          <div
            class="position-sticky"
            :class="$style[`product-img-area`]"
          >
            <div class="container-fluid p-0">
              <div
                id="productSubCarousel"
                class="row position-relative"
              >
                <div :class="$style[`imageZoomWrapper`]">
                  <InnerImageZoom
                    class="position-absolute"
                    :src="imageURLList[selectedImgIndex].img"
                    :zoom-src="imageURLList[selectedImgIndex].img"
                  />
                </div>
                <Slider
                  :allow-touch-move="true"
                  class="col-12"
                  :breakpoints="{
                    0: {
                      slidesPerView: 3
                    },
                    768: {
                      slidesPerView: 4
                    },
                    1024: {
                      slidesPerView: 4
                    }
                  }"
                  :space-between="14"
                >
                  <template #default>
                    <SwiperSlide
                      v-for="(img, index) in imageURLList"
                      :key="index"
                      class="col-4 col-md-3 p-0"
                      @mouseover="setSlideIndex(index)"
                    >
                      <img
                        class="w-100 h-100 p-1"
                        :class="[
                          $style[`object-fit-cover`],
                          $style[`transition-border`],
                          [
                            index === selectedImgIndex
                              ? [$style[`border-primary`]]
                              : [$style[`border-transparent`]]
                          ]
                        ]"
                        :src="img.img"
                      >
                    </SwiperSlide>
                  </template>
                </Slider>
              </div>
            </div>
          </div>
        </div>

        <!-- right info -->
        <div class="col-12 col-md-5 mt-4 mt-md-0 bg-white p-3">
          <h3 class="mb-0 text-start">
            {{ productDetail.name }}
          </h3>
          <!-- price & storage -->
          <div class="d-flex align-items-center justify-content-between">
            <div class="h4 mb-4 text-primary">
              $ {{ getThousands(selectedSpec.fixedPrice) }}
            </div>
            <!-- <div v-if="selectedSpec.storageQty > 0" class="h6 my-3">商品數量: {{getThousands(selectedSpec.storageQty)}}</div> -->
            <!-- <div v-if="selectedSpec.storageQty > 0" :class="selectedSpec.storageIsLow ? 'text-danger' : ''" class="h6 my-3">商品數量: {{getThousands(selectedSpec.storageQty)}}</div> -->
            <!-- <div v-else class="small my-3">目前無庫存</div> -->
          </div>
          <!-- <div class="text-primary" v-html="product.description"></div> -->
          <div class="text-start text-grey">
            {{ productDetail.name }}
          </div>
          <!-- form -->
          <!-- options -->
          <div class="d-flex flex-wrap mb-4">
            <!-- @mouseover="showSpecImg(spec.specID)" -->
            <div
              v-for="(spec, index) in specList"
              :key="index"
              class="border border-primary px-3 py-2 mb-1 me-1"
              :class="[
                { [$style['active']]: spec.specID === selectedSpec.specID },
                [$style['product-spec']],
                { 'opacity-50': spec.storageQty < 1 }
              ]"
              type="button"
              @click="spec.storageQty > 0 ? setSelectedSpec(spec) : ''"
            >
              {{ getSpecName(spec) }}
            </div>
          </div>
          <!-- 數量 -->
          <div
            v-if="selectedSpec.storageQty > 0"
            class="mb-4"
          >
            <h6 class="text-grey d-flex justify-content-between mb-0">
              數量
              <div
                v-if="selectedSpec.storageQty > 0"
                class="h6 text-grey"
              >
                還剩 {{ getThousands(selectedSpec.storageQty) }} 件
              </div>
              <div
                v-else
                class="small my-3 text-grey"
              >
                目前無庫存
              </div>
            </h6>
            <div class="input-group align-items-center border">
              <div
                role="button"
                class="px-3 py-2"
                @click="setNumber(number - 1)"
              >
                <span class="border-0 bg-white">
                  <Icon
                    name="minus"
                    :class="$style[`icon`]"
                  />
                </span>
              </div>
              <input
                v-model="number"
                type="number"
                min="1"
                :max="selectedSpec.storageQty"
                class="form-control text-center border-0"
                placeholder="數量"
                name="qty"
                aria-label="數量"
              >
              <div
                role="button"
                class="px-3 py-2"
                @click="setNumber(number + 1)"
              >
                <span
                  class="border-0 bg-white"
                ><Icon
                  name="plus"
                  :class="$style[`icon`]"
                /></span>
              </div>
            </div>
            <div class="text-end">
              <small
                class="text-danger"
                :class="[
                  $style[`error-text`],
                  { [$style[`show`]]: v$.number.$error }
                ]"
              >{{ v$.number?.$errors[0]?.$message || '' }}</small>
            </div>
          </div>
          <!-- button -->
          <div
            v-if="selectedSpec.storageQty > 0"
            class="d-flex flex-row justify-content-between mb-4"
          >
            <!-- <div
              v-if="selectedSpec.hasInCart"
              class="btn btn-lg btn-outline-primary w-100 rounded-0"
            >
              已加入購物車
            </div> -->
            <button
              v-if="selectedSpec.hasInCart"
              id="updateCartBtn"
              class="btn btn-lg btn-outline-primary w-100 rounded-0"
              :class="$style[`text-hover-white`]"
              type="button"
              @click="updateCartItem"
            >
              <!-- @click="createCartItem" -->
              更新購物車
            </button>
            <button
              v-if="!selectedSpec.hasInCart"
              id="AddCartBtn"
              class="btn btn-lg btn-outline-primary w-100 rounded-0"
              :class="$style[`text-hover-white`]"
              type="button"
              @click="createCartItem"
            >
              <!-- @click="createCartItem" -->
              加入購物車
            </button>
            <button
              v-if="!selectedSpec.hasInCart"
              class="btn btn-lg btn-primary text-white w-100 rounded-0 ms-2"
              type="button"
              @click="addToCartAndBuy"
            >
              立即購買
            </button>
          </div>
          <div
            v-else
            class="btn btn-lg btn-outline-primary w-100 rounded-0"
            :class="$style[`text-hover-white`]"
          >
            商品無庫存
          </div>
          <hr class="mb-0">

          <div class="text-start">
            <!-- 商品描述 -->
            <div class="border-bottom">
              <div
                role="button"
                @click="setShowInfoDesc"
              >
                <div
                  :class="[
                    $style[`info-title`],
                    { [$style[`collapsed`]]: !showInfoDesc }
                  ]"
                  class="py-3 d-flex justify-content-between align-items-center fw-bold"
                  role="button"
                >
                  商品描述
                  <Icon
                    name="plus"
                    class="rounded-circle border"
                    :class="[
                      $style[`icon`],
                      $style[`plus-icon`],
                      { 'd-none': showInfoDesc }
                    ]"
                  />
                  <Icon
                    name="minus"
                    class="rounded-circle border"
                    :class="[
                      $style[`icon`],
                      $style[`minus-icon`],
                      { 'd-none': !showInfoDesc }
                    ]"
                  />
                </div>
              </div>

              <div
                :class="[
                  $style[`info-content`],
                  { [$style[`collapsed`]]: !showInfoDesc }
                ]"
                v-html="productDetail.description"
              />
            </div>

            <!-- 敘述性規格 -->
            <div class="border-bottom">
              <div
                role="button"
                @click="setShowInfoSpec"
              >
                <div
                  :class="[
                    $style[`info-title`],
                    { [$style[`collapsed`]]: !showInfoSpec }
                  ]"
                  class="py-3 d-flex justify-content-between align-items-center fw-bold"
                  role="button"
                >
                  規格說明
                  <Icon
                    name="plus"
                    class="rounded-circle border"
                    :class="[
                      $style[`icon`],
                      $style[`plus-icon`],
                      { 'd-none': showInfoSpec }
                    ]"
                  />
                  <Icon
                    name="minus"
                    class="rounded-circle border"
                    :class="[
                      $style[`icon`],
                      $style[`minus-icon`],
                      { 'd-none': !showInfoSpec }
                    ]"
                  />
                </div>
              </div>

              <div
                :class="[
                  $style[`info-content`],
                  { [$style[`collapsed`]]: !showInfoSpec }
                ]"
              >
                <div v-if="specDesc.length > 0">
                  <div
                    v-for="(s, index) in specDesc"
                    :key="index"
                    class="h6 my-2"
                  >
                    <div class="">
                      {{ s.specName }}：{{ s.specValue }}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- 配送及付款方式 -->
            <div class="border-bottom">
              <div
                role="button"
                @click="setShowInfoShip"
              >
                <div
                  :class="[
                    $style[`info-title`],
                    { [$style[`collapsed`]]: !showInfoShip }
                  ]"
                  class="py-3 d-flex justify-content-between align-items-center fw-bold"
                  role="button"
                >
                  配送及付款方式
                  <Icon
                    name="plus"
                    class="rounded-circle border"
                    :class="[
                      $style[`icon`],
                      $style[`plus-icon`],
                      { 'd-none': showInfoShip }
                    ]"
                  />
                  <Icon
                    name="minus"
                    class="rounded-circle border"
                    :class="[
                      $style[`icon`],
                      $style[`minus-icon`],
                      { 'd-none': !showInfoShip }
                    ]"
                  />
                </div>
              </div>

              <div
                :class="[
                  $style[`info-content`],
                  { [$style[`collapsed`]]: !showInfoShip }
                ]"
                class="row col-12"
              >
                <div
                  v-if="payways.length > 0"
                  class="col-12 col-lg-6 mb-3"
                >
                  <div class="fw-bold mb-2">
                    付款：
                  </div>
                  <div
                    v-for="(p, index) in payways"
                    :key="index"
                    class="fs-6 mb-1"
                  >
                    <div>
                      {{ p.payway }}
                    </div>
                  </div>
                </div>
                <div
                  v-if="shipServices.length > 0"
                  class="col-12 col-lg-6 mb-3"
                >
                  <div class="fw-bold mb-2">
                    配送：
                  </div>
                  <div
                    v-for="(s, index) in shipServices"
                    :key="index"
                    class="fs-6 mb-1"
                  >
                    <div>{{ s.server }}：{{ s.fixedFee }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container-lg my-md-5 py-5">
      <h5 class="col-12 mx-auto py-3 text-primary text-center">
        <Icon
          :class="$style.diamond"
          name="diamond"
        />
        商品描述
        <Icon
          :class="$style.diamond"
          name="diamond"
        />
      </h5>
      <div v-if="copyWriter.length > 0">
        <div
          v-for="(c, index) in copyWriter"
          :key="index"
          class="row ql-editor"
        >
          <div
            class="col-12 mt-3"
            v-html="c?.text || ''"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { inject, ref, computed, reactive } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import Slider from '@/components/Slider.vue'
import { SwiperSlide } from 'swiper/vue'
import useVuelidate from '@vuelidate/core'
import InnerImageZoom from 'vue-inner-image-zoom'
import 'vue-inner-image-zoom/lib/vue-inner-image-zoom.css'
import {
  helpers,
  requiredHelper,
  numericHelper,
  between
} from '@/hooks/useVuelidate.js'
import { useErrorMessage } from '@/hooks/errorMessage.js'

export default {
  name: 'ProductInfo',
  components: {
    Slider,
    SwiperSlide,
    InnerImageZoom
  },
  setup (props) {
    const { push } = useRouter()
    const { dispatch, getters } = useStore()
    const { getErrorMessage, getSuccessMessage } = useErrorMessage()

    const memberID = computed(() => getters['member/getMemberID'])
    const productDetail = computed(() => getters['product/getProductDetail'])
    const copyWriter = computed(() => getters['product/getProductCopyWriter'])
    const specDesc = computed(() => getters['product/getProductSpecDesc'])
    const specList = computed(() => getters['product/getProductSpecList'])
    const imageURLList = computed(
      () => getters['product/getProductImageURLList']
    )
    const payways = computed(() => getters['payment/getPayways'])
    const shipServices = computed(() => getters['order/getShipServices'])

    // data
    const selectedSpec = inject('selectedSpec')
    const number = inject('number')
    // const productID = inject('productID')

    // function
    const setAlert = inject('setAlert')
    const readProduct = inject('readProduct')

    // UI
    const showInfoDesc = ref(false)
    const showInfoSpec = ref(false)
    const showInfoShip = ref(false)
    const selectedImgIndex = ref(0)

    // UI function
    const setShowInfoDesc = () => {
      showInfoDesc.value = !showInfoDesc.value
    }
    const setShowInfoSpec = () => {
      showInfoSpec.value = !showInfoSpec.value
    }
    const setShowInfoShip = () => {
      showInfoShip.value = !showInfoShip.value
    }
    const setSlideIndex = (index) => {
      selectedImgIndex.value = index
    }
    const setSelectedSpec = (spec) => {
      selectedSpec.value = spec
    }

    const getThousands = (num) => {
      return num
        ? Math.ceil(num)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        : 0
    }

    const imageLoadError = (e) => {
      e.target.src = require('@/assets/img/nopic.gif')
    }

    const state = reactive({
      specID: selectedSpec.value.specID,
      number
    })

    const minNumber = ref(1)
    const rules = {
      specID: {
        requiredHelper
      },
      number: {
        requiredHelper,
        numericHelper,
        betweenRef: helpers.withMessage(
          '請確認商品數量',
          between(
            minNumber,
            computed(() => selectedSpec.value.storageQty)
          )
        )
      }
    }

    const v$ = useVuelidate(rules, state)
    v$.value.$touch()

    const getSpecName = (spec) => {
      const hasSpec1 = spec.spec1Name !== '無' && spec.spec1Value !== '無'
      const hasSpec2 = spec.spec2Name !== '無' && spec.spec2Value !== '無'

      if (!hasSpec1 && !hasSpec2) {
        return '單一規格'
      } else if (hasSpec1 && !hasSpec2) {
        return `${spec.spec1Name}：${spec.spec1Value}`
      } else if (!hasSpec1 && hasSpec2) {
        return `${spec.spec2Name}：${spec.spec2Value}`
      } else {
        return `${spec.spec1Name}：${spec.spec1Value}, ${spec.spec2Name}：${spec.spec2Value}`
      }
    }

    const setNumber = (num) => {
      // 增加時數量不能超過庫存
      if (num > selectedSpec.value.storageQty) {
        number.value = selectedSpec.value.storageQty
        return
      }
      // 刪除時數量才可小於1
      if (num > 0) {
        number.value = num
      }
    }

    const readCartMount = async () => {
      const payload = { memberID: memberID.value }

      try {
        const response = await dispatch('cart/readCartMount', { payload })
        return response
      } catch (error) {
        setAlert(true, false, error)
        return Promise.reject(error)
      }
    }

    const readCart = async () => {
      if (!memberID.value) {
        setAlert(true, false, '請登入會員！')
        return null
      }
      const payload = { memberID: memberID.value, offset: 0, pageSize: 10 }

      try {
        const response = await dispatch('cart/readCart', { payload })
        return response
      } catch (error) {
        setAlert(true, false, error)
        return Promise.reject(error)
      }
    }

    // 加入購物車
    const updateCartItem = async () => {
      console.log('updateCartItem')
      if (!memberID.value) {
        setAlert(true, false, '請先登入')
        push('/login')
      } else if (!v$.value.specID.$errors) {
        setAlert(true, false, '請選擇規格！')
      } else if (!v$.value.$error) {
        const payload = {
          memberID: memberID.value,
          cartID: selectedSpec.value.cartID,
          qty: number.value
        }

        // 刪除
        try {
          const response = await dispatch('cart/updateCartItem', { payload })
          await readCart()
          await readCartMount()

          // getErrorMessage
          if (response.data.errMsg) {
            setAlert(true, false, getErrorMessage(response))
          } else {
            setAlert(true, true, getSuccessMessage(response))
          }

          return response
        } catch (error) {
          setAlert(true, false, `更新失敗: ${error}`)
        }
      }
    }

    // 加入購物車
    const createCartItem = async () => {
      // 會員登入檢核
      if (!memberID.value) {
        setAlert(true, false, '請登入會員！')
        push('/login')
      } else if (!v$.value.specID.$errors) {
        setAlert(true, false, '請選擇規格！')
      } else if (!v$.value.$error) {
        const payload = {
          qty: number.value,
          memberID: memberID.value,
          specID: selectedSpec.value.specID
        }

        try {
          const response = await dispatch('cart/createCartItem', { payload })

          // getErrorMessage
          if (response.data.errMsg) {
            setAlert(true, false, getErrorMessage(response))
          } else {
            await Promise.all([readProduct(), readCart(), readCartMount()])
            selectedSpec.value = specList.value.find(
              (item) => item.specID === selectedSpec.value.specID
            )
            setAlert(true, true, getSuccessMessage(response))
          }

          return response
        } catch (error) {
          setAlert(true, false, `加入失敗: ${error}`)
          return Promise.reject(error)
        }
      }
    }

    // 直接購買
    const addToCartAndBuy = async () => {
      if (selectedSpec.value.hasInCart) {
        await updateCartItem()
        push('/cart')
      } else {
        await createCartItem()
        push('/cart')
      }
    }

    return {
      selectedImgIndex,
      getSpecName,
      specList,
      productDetail,
      copyWriter,
      payways,
      updateCartItem,
      createCartItem,
      v$,
      shipServices,
      setNumber,
      setSelectedSpec,
      imageLoadError,
      getThousands,
      addToCartAndBuy,
      selectedSpec,
      specDesc,
      number,
      imageURLList,
      setSlideIndex,
      showInfoDesc,
      showInfoSpec,
      showInfoShip,
      setShowInfoDesc,
      setShowInfoSpec,
      setShowInfoShip
    }
  }
}
</script>
<style lang="scss" module>
.imageZoomWrapper {
  padding-bottom: 135%;
}

.slider-height {
  height: 400px;
}

.object-fit-cover {
  object-fit: cover;
}

.text-hover-white:hover {
  color: $white;
}

.transition-border {
  transition: border 0.3s;
}

.border-transparent {
  border: solid 1px transparent;
}

.border-primary {
  border: solid 1px $primary;
}

.icon {
  height: 1.2rem;
  padding: 0.2rem;
  width: 1.2rem;
}

.diamond {
  fill: $primary;
  height: 1.2rem;
  margin: 0 0.5rem;
  width: 1.2rem;
}

.product-spec {
  border: solid 1px $grey;
  transition: border-color 0.3s, background-color 0.3s, color 0.3s;
}

.product-spec:hover {
  color: $primary;
}

.product-spec.active {
  background-color: $primary;
  border: solid 1px $primary;
  color: $white;
}

@media screen and (min-width: 768px) {
  .product-img-area {
    top: 15vw;
  }
}

.info-content {
  max-height: 200vh;
  overflow: hidden;
  transition: max-height 0.3s;
}

.info-content.collapsed {
  max-height: 0;
}

.error-text {
  display: inline-block;
  opacity: 0;
  overflow: hidden;
  transition: opacity 0.3s;
}

.error-text.show {
  opacity: 1;
}
</style>
